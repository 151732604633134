
.select-teaching-language {
  display: flex;
  flex-direction: column;
  align-items: center;

  .select-teaching-language__languages {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .select-teaching-language__language {
    margin: 10px
  }

  .select-teaching-language__ingress {
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    &--small {
      font-size: 16px;
      word-break: break-word;
    }
  }
}
