.main {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .main__container {
    margin: 5px;
    flex-grow: 1;
  }
  .main__content {
    margin: 0 auto;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  h1 {
    text-align: center;
  }

  .main__footer-content {
    flex-grow: 1;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 70px;
    padding: 0 10px;

    @media (max-width: 800px) {
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .main__footer {
    margin-top: 10px;
    border-top: 1px solid #dfdfdf;
    background-color: #fafafa;
    justify-content: center;
    display: flex;
    flex-direction: row;
  }
  .main__logo {
    display: flex;
    align-items: center;
    padding-right: 10px;
  }

  .main__logo-image {
    height: 100%;
    padding-right: 15px;
  }

  .main__footer-disclamer {
    font-family: 'Lato', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    color: #7c7c7c;
    align-self: center;
  }

  .main__select-language-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .main__language-select-button {
    border-radius: 0;
    background-color: transparent;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 15px;
    padding: 0;
    margin: 5px;
    height: 24px;
    font-weight: 400;

    &--selected {
      font-weight: 700;
      border-bottom: 2px solid #000000;
    }
  }
}
