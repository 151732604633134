
.select-exams {
  .select_exams__selected-teaching-language {
    text-align: center;
    margin: 20px 0 0 0;
  }

  .select-exams__inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .select_exams__buttons {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
}
