
@import "@fontsource/lato/300.css";
@import "@fontsource/lato/400.css";
@import "@fontsource/lato/400-italic.css";
@import "@fontsource/lato/700.css";
@import "@fontsource/source-sans-pro/400.css";
@import "@fontsource/source-sans-pro/700.css";

* {
  font-family: "Lato", sans-serif;
}

button {
  width: auto;
  height: 40px;
  background: #2C7F96;
  color: #FFFFFF;
  border: 0 none;
  padding: 10px 20px;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
}
