
.exam-input {
  height: 40px;
  margin: 0 20px 10px 20px;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 10px;
  width: 300px;
}
